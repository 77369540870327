import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PageLoader from '../../components/loader/PageLoader';
import useAxiosInterceptors from '../../axios/useAxios';
import { findUser, saveUser } from '../../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../../firebase/firebaseConfig.js'
import mail from '../../styles/img/mail.svg'
import HandleGoogle from './login/HandleGoogle.jsx';
import HandleFacebook from './login/HandleFacebook.jsx';
import PikkoPointHeader from '../../components/card/PikkoPointHeader';
import BrandList from '../brand/BrandList';
import ScanFooter from './ScanFooter';
import NavigationMenu from '../../components/slider/NavigationMenu';
import { ACCOUNT, HOME, INVITATION, PROMO } from '../../constants/routes';
import BackButton from '../../components/button/BackButton.jsx';
import inscriptionHeader from '../../styles/img/account/inscriptionHeader.svg'
import connexionHeader from '../../styles/img/account/connexionHeader.svg'
import UserInfo from '../../components/UserInfo.jsx';
import Email from './login/Email.jsx';
import GetUserInfo from './GetUserInfo.jsx';
import fid_card from '../../styles/img/fid_card.svg';
import { authenticate } from '../../actions/authenticateActions';
import { notifyWarning } from '../../utils/Notify';
import SwipperTuto from '../../components/Swipper';
import step1 from '../../styles/img/tuto/step1.svg';
import step2 from '../../styles/img/tuto/step2.svg';
import step3 from '../../styles/img/tuto/step3.svg';
import questionMark from '../../styles/img/question-mark.svg';

const AccountScreen = () => {

  const [showPopup, setShowPopup] = useState(false);
  const { prevPage, resetEmail } = useLocation().state || '';
  const navigate = useNavigate()
  const axiosInstance = useAxiosInterceptors()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('');
  const user = useSelector(state => state.user)
  const [state, setState] = useState('')
  const { cartItems } = useSelector(state => state.cart)
  const [forgot, setForgot] = useState(false)
  const [brands, setBrands] = useState([]);
  const [showNavigationMenu, setShowNavigationMenu] = useState(false);
  const [loadingConnexion, setLoadingConnexion] = useState(false);
  const [fidCompany, setFidCompany] = useState(false)
  const [fidExist, setFidExist] = useState(false)
  const refComposant = useRef();
  useEffect(() => {
    auth.onAuthStateChanged(async (firebaseUser) => {
      console.log(firebaseUser)
      if (user && user._id && user.email) {
        setLoadingConnexion(false);
      } else if (firebaseUser && firebaseUser.email) {
        setLoadingConnexion(true);
        await GetRedirectInfo(auth, user);
      } else {
        setLoadingConnexion(false);
      }
    })
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
    if (user && user._id && user.email && auth.currentUser) {
      dispatch(findUser(user.email, axiosInstance, navigate))
    }
    axiosInstance.get('/brands')
                 .then((response) => {
                   setBrands(response.data);
                 })
  }, []);

  useEffect(() => { // REVIEW : Dans cette classe il y a 3 useEffect qui écoutent la même variable (user)
    if (user && user.email) {
      setEmail(user.email)
    }
    if (resetEmail && resetEmail.length > 0) {
      setEmail(resetEmail)
    }
    if (user && user.email && prevPage === 'invitation') {
      navigate(PROMO, { state: { prevPage: 'account' } })
      localStorage.removeItem('popupUser')
    }
  }, [user]);

  const handleGoogle = async () => {
    if (localStorage.getItem('newuser')) {
      localStorage.removeItem('newuser')
    }
    try {
      await HandleGoogle(auth, user);
    } catch (e) {
      alert('Une erreur s\'est produite')
    }
  }

  const GetRedirectInfo = async (auth, user) => {
    try {
      const data = await GetUserInfo(auth, user);
      authenticate(axiosInstance, data.newResult.profile.email, data.result.user.accessToken)
        .then(() => {
          dispatch(saveUser(data.newResult.profile, axiosInstance, cartItems, navigate));
        })
        .catch((error) => {
          if (error.response.data.errorCode === 'USER_FID_CARD_ALREADY_USED') {
            notifyWarning('Oups', 'L\'email ne correspond à la carte de fidélité', 'Ok');
          } else {
            notifyWarning('Oups', 'Une erreur est survenue lors de ta connexion.', 'Ok');
          }
          console.error('Authenticate', error);
        })
        .finally(() => setLoadingConnexion(false))
    } catch (error) {
      console.error('Une erreur s\'est produite lors de l\'authentification avec Google:', error);
    }
  }

  const handleFacebook = async (e) => {
    if (localStorage.getItem('newuser')) {
      localStorage.removeItem('newuser')
    }

    try {
      const data = await HandleFacebook(auth, user)
      console.log(data.newResult.profile)
      dispatch(saveUser(data.newResult.profile, axiosInstance, cartItems, navigate));
      navigate(ACCOUNT)

    } catch (e) {
      alert('Une erreur s\'est produite')
    }
  }

  const inscription = () => {
    window.scrollTo(0, 0)
    // setPagetitle('Créer un compte gratuit')
    setState('signUp')
    if (document.getElementById('page_account'))
      document.getElementById('page_account').scrollTop = 0
  }
  const connexion = () => {
    window.scrollTo(0, 0)
    // setPagetitle('Connexion')
    setState('signIn');
    if (forgot) setForgot(false)
    if (document.getElementById('page_account'))
      document.getElementById('page_account').scrollTop = 0
  }

  useEffect(() => {
    const element = document.querySelector('.cercle_account_coin_border');
    if (element) {
      element.style.height = `${user.fidelity / 70 * 100}%`;
    }
  }, [user]);

  // if (loadingConnexion) {
  //   return (<PageLoader />);
  // }
  const CheckIfFidExist = () => {
    if (fidExist) {
      setFidCompany(!fidCompany)
    }

  }
  const tutoRef = useRef();
  const closepopup = () => {
    setShowPopup(false)
  };
  const [TutoCurrentIndex, setTutoCurrentIndex] = useState(0);
  const [view, setView] = useState(false);
  const onClickHelp = () => {
    setView(!view);
    setTutoCurrentIndex(0);
  };
  return (
    <>
      <div id="page_account" className="w-full h-full overflow-y-scroll  ">
        {user && user._id && user._id.length > 0 ? <UserInfo /> : <></>}
        <NavigationMenu
          isOpen={showNavigationMenu}
          onClose={() => setShowNavigationMenu(false)}
          user={user}
        />
        <div className={`page_account_body pb-10${user && user._id ? '' : ' height_normal bg-gradient1 '}`}>

          {user.userLoading && <PageLoader />}
          {user && user._id ? (
            <div className="flex flex-col justify-start items-start w-full h-full">
              <div className="h-full w-full  ">
                <div className="question-mark-account" onClick={onClickHelp}>
                  <img src={questionMark} alt="" />
                </div>
                <div className="page_account_content">
                  <PikkoPointHeader user={user} notEuroVersion={true} isMainPage
                                    onClick={() => setShowNavigationMenu(true)} />
                  <BrandList brands={brands} originPath={ACCOUNT} showTitle showPromoText />
                </div>
                <SwipperTuto
                  ref={tutoRef}
                  close={onClickHelp}
                  TutoCurrentIndex={TutoCurrentIndex}
                  setTutoCurrentIndex={setTutoCurrentIndex}
                  view={view} slides={[

                  <div key="1" className="tuto_box">
                    <div className="tuto-container">
                      <img src={step1} alt="step1" />
                      <p className="tuto-bold">SCANNER</p>
                      <div className="tuto-text">
                        <span
                          className="tuto-title">Scanne les codes barres de tes articles pour les ajouter au panier</span>
                      </div>
                    </div>
                  </div>,
                  <div key="2" className="tuto_box">
                    <div className="tuto-container">
                      <img src={step2} alt="step2" />
                      <p className="tuto-bold">PAYER</p>
                      <div className="tuto-text">
                        <span className="tuto-title">Paye tes courses sur ton smartphone sans faire la queue</span>
                      </div>
                    </div>
                  </div>,
                  <div key="3" className="tuto_box">
                    <div className="tuto-container">
                      <img src={step3} alt="step3" />
                      <p className="tuto-bold">VALIDER</p>
                      <div className="tuto-text">
                        <span
                          className="tuto-title">Dirige-toi vers la sortie express et scanne le QR code de sortie</span>
                      </div>
                    </div>
                  </div>

                ]} />
              </div>
            </div>
          ) : (
            <>
              <BackButton onClick={() => {
                if (prevPage === 'invitation') navigate(INVITATION)
                else navigate(HOME)
              }} isWhite={false} />
              <div className="header-svg-container">
                <img src={state === 'signUp' ? inscriptionHeader : connexionHeader} alt="header"
                     className="header-svg" />
              </div>

              <button
                onClick={() => {
                  state === 'signUp' ? connexion() : inscription();
                }}
                className="text-gray-500 hover:text-gray-700 focus:outline-none my-4 w-full sticky"
              >
                {state === 'signUp' ? (
                  <>Déjà un compte ? <strong className="bold_blue ">Connexion</strong></>
                ) : (
                  <>Pas encore de compte ? <strong className="bold_blue ">Créer un compte !</strong></>
                )}
              </button>

              <div className=" h-full w-screen flex flex-col justify-start items-start p-6 ">

                <div className="flex flex-col gap-14 justify-start py-6 items-center w-full">

                  <div className="flex items-center justify-center space-x-4   gap-8">
                    {/* <RenderButton/> */}

                    <button onClick={CheckIfFidExist} className="border-none">
                      {fidCompany ? (
                        <img className="h-[50px] w-auto" src={mail} alt="google" />
                      ) : (
                        <img className="h-[50px] w-auto " src={fid_card} alt="fid" />
                      )}
                    </button>
                    <div className="text-3xl font-black w-full flex items-center justify-center gap-4 flex-nowrap">
                      Ou
                    </div>
                    <button onClick={handleGoogle} className="border-none">
                      <img className="h-[50px] w-auto" src="/images/LoginGoogle.png" alt="google" />
                    </button>
                  </div>
                </div>
                <Email
                  user={user}
                  cartItems={cartItems}
                  ref={refComposant}
                  resetEmail={resetEmail}
                  axiosInstance={axiosInstance}
                  state={state}
                  setState={setState}
                  forgot={forgot}
                  setForgot={setForgot}
                  connexion={connexion}
                  inscription={inscription}
                  fidCompany={fidCompany}
                  setFidCompany={setFidCompany}
                  fidExist={fidExist}
                  setFidExist={setFidExist}
                />
              </div>
            </>)
          }
        </div>
        {
          user && user._id ? <ScanFooter originPath={ACCOUNT} /> : <></>
        }
      </div>
    </>
  );
};

export default forwardRef(AccountScreen);
