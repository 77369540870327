import { PaymentRequestButtonElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from '../../components/Toast';
import { failPaidOrder, payOrder, successPaidOrder } from '../../actions/cartActions';
import { addUsedPromo, addUserApplePayEmail, countPromo } from '../../actions/userActions';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from '../../utils/WsUtils';
import { getUserEmail } from '../../utils/StringUtils';
import { WsError } from '../../constants/wsError';

const ApplePay = (order, axiosInstance, canPay, setPaymentErrorMessage) => {
  ({ order, axiosInstance, canPay } = order)
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [paymentRequest, setpaymentRequest] = useState(null);
  const [loadingaxios, setLoadingAxios] = useState(false)
  const [email1, setEmail1] = useState('');
  const [link, setLink] = useState(false)
  const [applePayAvailable, setApplePayAvailable] = useState(false);
  const [googlePayAvailable, setGooglePayAvailable] = useState(false);

  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  const { cartItems, loadingCart } = useSelector((state) => state.cart);
  const userRef = useRef(user);

  let paymentIntent, email, promotion, pr

  const toPrice = (num) => (parseFloat(num).toFixed(2))
  // const successPaymentHandler = (email1, order_amount) =>{
  //     dispatch(payOrder(order, email1, paymentIntent, axiosInstance))

  //     /*.then(() => {
  //            dispatch(getCartInfo(order._id, axiosInstance));
  //            axiosInstance.put("/track/cartscreen", { id: order._id });})*/
  // }
  const calculateOrderAmount = () => {
    if (cartItems.promoPrice && cartItems.promoPrice > 0) {
      return cartItems.promoPrice
    } else {
      return cartItems.itemsPrice
    }
  }
  const vibrationButton = () => {
    console.log(canPay)
    if (document.querySelector('.conditioncheckbox')) {
      document.querySelector('.conditioncheckbox').classList.add('vibrationred')
      setTimeout(() => {
        document.querySelector('.conditioncheckbox').classList.remove('vibrationred')
      }, 1000);
    }
  }
  const successPaymentHandler = (userInfo, paymentIntentVar, email) => {
    Toast('success', 'Paiement réussi');
    console.log(paymentIntentVar)

    userInfo.apple_email = email
    dispatch(
      payOrder(cartItems, email, paymentIntentVar, 'applePay/googlePay', axiosInstance)
    );
    if (cartItems.promo.length > 0) {
      dispatch(addUsedPromo(user, axiosInstance, cartItems))
    }
    // Si le user n'a pas utilisé son code promo et est venu 3 fois alors ne pas trigger count pour le garder à 3
    if (user.count == 3 && cartItems.promo !== 'promoGift') {
      console.log(user.count)
      console.log(cartItems.promo)
      console.log('rien')
      dispatch(addUserApplePayEmail(userInfo, axiosInstance))
    } else {
      // COUNT PROMO SE TRIGGER MAIS LA CONDITION USER.EXIST N'EST PAS REMPLIE
      console.log(userInfo)
      dispatch(countPromo(userInfo, axiosInstance))
      dispatch(addUserApplePayEmail(userInfo, axiosInstance))
    }
    // navigate(`/ScanCheck`)
  };
  const options = {
    paymentRequest,
    style: {
      paymentRequestButton: {
        type: 'default',
        // One of 'default', 'book', 'buy', or 'donate'
        // Defaults to 'default'

        theme: 'dark',
        // One of 'dark', 'light', or 'light-outline'
        // Defaults to 'dark'

        height: '50px',
        // Defaults to '40px'. The width is always '100%'.
      },
    }
  }

  const handleError = (errorMessage, errorLabel, errorContent) => {
    console.error(errorMessage, errorLabel, errorContent);
    setPaymentErrorMessage(errorMessage);
    dispatch(failPaidOrder(errorMessage));
  };

  const dispatchData = (data) => {
    dispatch(successPaidOrder(data));
    dispatch(addUsedPromo(user, axiosInstance, order));
  };

  const updateOrder = (paymentIntent) => { // TODO : mettre en commun avec applepay
    const email = getUserEmail(user);
    axiosInstance.put(`/orders/${order._id}/pay`, { email, paymentIntent, methodName: 'cb' })
      .then((response) => {
        dispatchData(response.data);
      })
      .catch((error) => {
        handleError(WsError.PAY_ORDER_ERROR, 'Error while calling payOrder endpoint', error);
      })
  };

  const confirmPayment = (clientSecret) => {
    stripe.confirmCardPayment(clientSecret) // TODO : ajouter le loading
      .then((three_d) => {
        if (three_d.paymentIntent?.status === 'succeeded') {
          updateOrder(three_d.paymentIntent);
        } else if (three_d.error) {
          handleError(WsError.STRIPE_PAYMENT_CONFIRM_ERROR, '3D payment verification failed, code :', three_d.error.code);
        } else {
          handleError(WsError.STRIPE_PAYMENT_CONFIRM_STATUS_ERROR, 'Cannot handle payment status in 3D payment, status :', three_d.paymentIntent?.status);
        }
      })
      .catch((error) => {
        handleError(WsError.STRIPE_PAYMENT_CONFIRM_ERROR, '3D payment verification failed, error :', error);
      })
  };

  useEffect(() => {
    setLoadingAxios(true);
    userRef.current = user;
    const createPaymentRequest = async () => {
      if (stripe) {
        try {
          const newOrderAmount = toPrice(calculateOrderAmount()); // TODO : attention tester un paiement avec les promos
          pr = stripe.paymentRequest({
            currency: 'eur',
            country: 'FR',
            requestPayerEmail: false,
            requestPayerName: false,
            requestShipping: false,
            requestPayerPhone: false,
            total: {
              label: 'PikkoPay',
              amount: Math.round(newOrderAmount * 100),
            },
          })
          const result = await pr.canMakePayment();
          if (result) {
            setApplePayAvailable(result.applePay);
            setGooglePayAvailable(result.googlePay);
            setpaymentRequest(pr);
            setLink(true);
          }
          setLoadingAxios(false);

          pr.on('paymentmethod', async (e) => {
            setLoadingAxios(true);

            const userEmail = user?.email || e.payerEmail;
            const userName = user && user.prenom && user.nom
              ? user.prenom + ' ' + user.nom
              : e.payerName;

            axiosInstance.post('/stripe/payment/apple', {
              amount: newOrderAmount,
              storeId: order.storeId,
              orderId: order._id,
              paymentMethod: e.paymentMethod.id,
              userEmail,
              userName
            })
              .then((response) => {
                const { data } = response;
                if (data.order) {
                  e.complete('success');
                  dispatchData(data);
                } else if (data.status === 'requires_action' && data.clientSecret) {
                  confirmPayment(data.clientSecret);
                } else {
                  handleError(WsError.STRIPE_PAYMENT_INTENT_ERROR, 'Unexpected response from Apple intent payment', data);
                }
              })
              .catch((error) => {
                e.complete('fail');
                const errorMessage = getErrorMessage(error);
                handleError(errorMessage, 'Apple intent payment failed', error);
              })
              .finally(() => setLoadingAxios(false))
          })
        } catch (error) {
          console.error('Error creating Payment Request:', error);
          setLoadingAxios(false);
        }
      }
    }
    createPaymentRequest();
  }, [stripe, elements, user, cartItems])

  return (
    <div>
      {loadingCart || loadingaxios
        ? (<div>Chargement...</div>)
        : (
        <>
          {(applePayAvailable || googlePayAvailable) && (
              <>
                {!user?._id && !canPay && (<button className="w-full h-[50px] absolute z-[999]" onClick={vibrationButton} />)}
                {paymentRequest && <PaymentRequestButtonElement options={options} />}
              </>
            )}
        </>
      )}
    </div>
  );
};

export default ApplePay;
