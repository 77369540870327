import React, { useEffect, useRef, useState } from 'react';
import * as SDCCore from 'scandit-web-datacapture-core';
import * as SDCBarcode from 'scandit-web-datacapture-barcode';

const ScannerTest = ({
                       onClick,
                       preloadBlurryRecognition,
                       preloadEngine,
                       accessCamera,
                       guiStyle,
                       viewFinderArea,
                       onScan,
                       pause,
                       destroy,
                       scanSettings,
                       videoFit,
                       playSoundOnScan,
                       enableCameraSwitcher,
                       enablePinchToZoom,
                       enableTapToFocus,
                       enableTorchToggle,
                       mirrorImage,
                       targetScanningFPS,
                       zoom
                     }) => {
  const scannerRef = useRef(null);
  const contextRef = useRef(null);
  const barcodeCaptureRef = useRef(null);

  const [camera, setCamera] = useState(null);

  const barcodeCaptureSettings = new SDCBarcode.BarcodeCaptureSettings();
  barcodeCaptureSettings.enableSymbologies([
                                             SDCBarcode.Symbology.Code128,
                                             SDCBarcode.Symbology.QR,
                                             SDCBarcode.Symbology.EAN8,
                                             SDCBarcode.Symbology.EAN13UPCA,
                                           ]);
  barcodeCaptureSettings.codeDuplicateFilter = 2500

  useEffect(() => {
    const licenseKey = process.env.REACT_APP_SCANDIT_KEY;

    const initializeScanner = async () => {
      try {
        await SDCCore.configure({
                                  licenseKey: licenseKey,
                                  libraryLocation: new URL('https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.21/build/engine', document.baseURI).toString(),
                                  moduleLoaders: [SDCBarcode.barcodeCaptureLoader()],
                                });

        const context = await SDCCore.DataCaptureContext.create(licenseKey);
        contextRef.current = context;

        const view = await SDCCore.DataCaptureView.forContext(context);
        console.log('SCANNER REF', scannerRef.current)
        view.connectToElement(scannerRef.current);
        await view.setContext(context);

        view.showProgressBar();
        view.setProgressBarMessage('Loading ...');

        const camera = SDCCore.Camera.default;
        setCamera(camera);
        const cameraSettings = new SDCCore.CameraSettings();
        cameraSettings.preferredResolution = 'fullHd';
        await camera.applySettings(cameraSettings);
        context.setFrameSource(camera);
        await camera.switchToDesiredState(SDCCore.FrameSourceState.On);

        console.log(barcodeCaptureSettings)
        const barcodeCapture = await SDCBarcode.BarcodeCapture.forContext(context, barcodeCaptureSettings);
        console.log(barcodeCapture)
        barcodeCaptureRef.current = barcodeCapture;
        await barcodeCapture.setEnabled(!pause);

        barcodeCapture.addListener({
                                     didScan: (barcodeCaptureMode, session) => {
                                       const recognizedBarcodes = session.newlyRecognizedBarcodes;
                                       if (onScan) {
                                         onScan({ barcodes: recognizedBarcodes });
                                       }
                                     },
                                   });

        const barcodeCaptureOverlay = await SDCBarcode.BarcodeCaptureOverlay.withBarcodeCaptureForView(
          barcodeCapture, view
        );
        const viewfinder = new SDCCore.RectangularViewfinder(
          SDCCore.RectangularViewfinderStyle.Rounded,
          SDCCore.RectangularViewfinderLineStyle.Bold
        );
        view.scanAreaMargins = new SDCCore.MarginsWithUnit(
          new SDCCore.NumberWithUnit(0, SDCCore.MeasureUnit.Pixel),
          new SDCCore.NumberWithUnit(0, SDCCore.MeasureUnit.Pixel),
          new SDCCore.NumberWithUnit(0, SDCCore.MeasureUnit.Pixel),
          new SDCCore.NumberWithUnit(125, SDCCore.MeasureUnit.Pixel)
        );
        viewfinder.setHeightAndAspectRatio(
          new SDCCore.NumberWithUnit(210, SDCCore.MeasureUnit.Pixel),
          1.6
        )
        viewfinder.dimming = 0.5;
        viewfinder.orientation = SDCCore.Orientation.PortraitUpsideDown
        viewfinder.color = SDCCore.Color.fromHex('#F3E226FF');
        await barcodeCaptureOverlay.setViewfinder(viewfinder);
        // await barcodeCaptureOverlay.setShouldShowScanAreaGuides(true)
        await view.addOverlay(barcodeCaptureOverlay)
        view.hideProgressBar()
      } catch (error) {
        console.error('Error initializing scanner:', error);
      }
    };

    initializeScanner();

    return () => {
      if (contextRef.current) {
        contextRef.current.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (barcodeCaptureRef.current) {
      barcodeCaptureRef.current.setEnabled(!pause);
    }
  }, [pause, barcodeCaptureRef]);

  useEffect(() => {
    if (destroy && contextRef.current) {
      contextRef.current.dispose();
      contextRef.current = null;
    }
  }, [destroy]);

  return (
    <div className="absolute" ref={scannerRef} style={{ width: '100%', height: '100%' }} onClick={onClick}></div>
  );
};

export default ScannerTest;