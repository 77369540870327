import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AccountScreen from './pages/account/AccountScreen';
import packageInfo from '../package.json';
import CacheBuster from 'react-cache-buster';
import Scan from './components/Scan';
import PageLoader from './components/loader/PageLoader';
import RedirectPage from './pages/RedirectPage';
import { ToastInternet } from './components/Toast';
import { toast } from 'react-toastify';
import CartScreen from './pages/CartScreen';
import {
  ACCOUNT,
  ADD_CB,
  ADD_FID_CARD,
  ADD_FID_SCAN,
  ADD_PAYMENT,
  BRAND,
  BRAND_DETAIL,
  CART,
  CHECK,
  COUPONS,
  EDENRED,
  FIDLOGIN,
  GIFT,
  HELP,
  HOME,
  INVITATION,
  LOGIN_FID_CARD,
  LOGIN_FID_SCAN,
  ORDER_SUCCESS,
  POPUP_PAYMENT,
  PRE,
  PRODUCT,
  PROMO,
  CONVERSION,
  RECOMPENSES,
  RESETPASSWORD,
  REWARD,
  SCAN,
  SCAN_CHECK,
  STORE_LIST,
  TICKETS,
  USER_FID_CARD,
  USER_FID_CARD_DETAIL,
  WALLET
} from './constants/routes';
import UserFidelityCard from './pages/account/fid/UserFidelityCard';
import UserFidelityCardDetail from './pages/account/fid/UserFidelityCardDetail';
import UserAddFidCardScreen from './pages/account/fid/add-card/UserAddFidCardScreen';
import UserAddFidScanScreen from './pages/account/fid/add-card/UserAddFidScanScreen';

// const CartScreen = React.lazy(() => import('./pages/CartScreen'));
const ProductScreen = React.lazy(() => import('./pages/ProductScreen'));
//const Scan = React.lazy(() => import('./components/Scan'));
const OrderCheck = React.lazy(() => import('./pages/OrderCheck'));
const OrderSuccess = React.lazy(() => import('./pages/OrderSuccess'));
//const RedirectPage = React.lazy(() => import('./pages/RedirectPage'))
const ScanCheck = React.lazy(() => import('./pages/ScanCheck'))
// const HomeLoader = React.lazy(() => import('./components/HomeLoader'))
const EdenredScreen = React.lazy(() => import('./pages/EdenredScreen'))
const PopupPayment = React.lazy(() => import('./payments/PopupPayment'))
const GiftScreen = React.lazy(() => import('./pages/GiftScreen'))
const InvitationScreen = React.lazy(() => import('./pages/Invitation'))
const ResetPassword = React.lazy(() => import ('./pages/account/login/ResetPassword'))
const PromoScreen = React.lazy(() => import('./pages/promo/PromoScreen'))
const PromoConversionScreen = React.lazy(() => import('./pages/promo/PromoConversionScreen'))
const TicketScreen = React.lazy(() => import ('./pages/account/TicketScreen'))
const Recompenses = React.lazy(() => import ('./pages/account/Recompense'))
const Coupon = React.lazy(() => import ('./pages/account/Coupon'))
const Help = React.lazy(() => import ('./pages/account/HelpScreen'))
const StoreListScreen = React.lazy(() => import('./pages/StoreListScreen'))
const WelcomeScreen = React.lazy(() => import('./pages/WelcomeScreen'))
const RewardScreen = React.lazy(() => import('./pages/gift/RewardScreen'))
const BrandDetailScreen = React.lazy(() => import('./pages/brand/BrandDetailScreen'))
const BrandPartnerScreen = React.lazy(() => import('./pages/brand/BrandPartnerScreen'))
const AddPaymentScreen = React.lazy(() => import('./pages/account/addPayment/AddPaymentScreen'))
const AddCbWrapper = React.lazy(() => import('./pages/account/addPayment/AddCbWrapper'))
const Wallet = React.lazy(() => import('./pages/account/addPayment/Wallet'))
const LoginAddFidCardScreen = React.lazy(() => import('./pages/account/fid/add-card/LoginAddFidCardScreen'))
const LoginAddFidScanScreen = React.lazy(() => import('./pages/account/fid/add-card/LoginAddFidScanScreen'))
const FidLoginScreen = React.lazy(() => import('./pages/account/fid/FidLoginScreen'))

function App() {

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const prevOnlineStatus = useRef(isOnline);

  useEffect(() => {
    const handleOnline = () => {
      if (!prevOnlineStatus.current) {
        toast.dismiss();
        ToastInternet('internet', 'connexion rétablie');
        setTimeout(() => {
          toast.dismiss();
        }, 2000);
      }
      setIsOnline(true);
      prevOnlineStatus.current = true;
    };

    const handleOffline = () => {
      setIsOnline(false);
      prevOnlineStatus.current = false;
      ToastInternet('noInternet', 'Aucune connexion internet');
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  // const location = useLocation();

  // console.log('hash', location.hash);
  window.scrollTo(0, 0);

  return (
    <CacheBuster currentVersion={packageInfo.version} isEnabled={true}>
      <BrowserRouter>
        <div className="home">
          <main>
            <React.Suspense fallback={<PageLoader />}>
              <Routes>
                <Route path="/" element={<RedirectPage />}></Route>
                <Route path={STORE_LIST} element={<StoreListScreen />} />
                <Route path={HOME} element={<WelcomeScreen />} />
                <Route path={SCAN} element={<Scan />}></Route>
                <Route path={INVITATION} element={<InvitationScreen />}></Route>
                <Route path={PRODUCT} element={<ProductScreen />}></Route>
                <Route path={EDENRED} element={<EdenredScreen />}></Route>
                <Route path={CHECK} element={<OrderCheck />}></Route>
                <Route path={ORDER_SUCCESS} element={<OrderSuccess />}></Route>
                <Route path={SCAN_CHECK} element={<ScanCheck />}></Route>
                <Route path={ACCOUNT} element={<AccountScreen />}></Route>
                <Route path={RESETPASSWORD} element={<ResetPassword />}></Route>
                <Route path={TICKETS} element={<TicketScreen />}></Route>
                <Route path={COUPONS} element={<Coupon />}></Route>
                <Route path={RECOMPENSES} element={<Recompenses />}></Route>
                <Route path={HELP} element={<Help />}></Route>
                <Route path={CART} element={<CartScreen />}></Route>
                <Route path={POPUP_PAYMENT} element={<PopupPayment />} />
                <Route path={GIFT} element={<GiftScreen />} />
                <Route path={REWARD} element={<RewardScreen />} />
                <Route path={BRAND_DETAIL} element={<BrandDetailScreen />} />
                <Route path={BRAND} element={<BrandPartnerScreen />} />
                <Route path={PROMO} element={<PromoScreen />} />
                <Route path={CONVERSION} element = {<PromoConversionScreen />} />
                <Route path={WALLET} element={<Wallet />} />
                <Route path={ADD_PAYMENT} element={<AddPaymentScreen />} />
                <Route path={ADD_CB} element={<AddCbWrapper />} />
                <Route path={LOGIN_FID_CARD} element={<LoginAddFidCardScreen />} />
                <Route path={LOGIN_FID_SCAN} element={<LoginAddFidScanScreen />} />
                <Route path={ADD_FID_CARD} element={<UserAddFidCardScreen />} />
                <Route path={ADD_FID_SCAN} element={<UserAddFidScanScreen />} />
                <Route path={FIDLOGIN} element={<FidLoginScreen />} />
                <Route path={USER_FID_CARD} element={<UserFidelityCard />} />
                <Route path={USER_FID_CARD_DETAIL} element={<UserFidelityCardDetail />} />
                {/* <Route path="/Recompense" element = {<Recompense/>} /> */}
              </Routes>
            </React.Suspense>
          </main>
        </div>
      </BrowserRouter>
    </CacheBuster>
  );
}

// git remote set-url origin https://github.com/lysot/Jilsfront.g
// npm install --legacy-peer-deps
export default App;
