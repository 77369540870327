import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosInterceptors from '../axios/useAxios';
import PopupPayment from './PopupPayment';
import ApplePay from './Stripe/ApplePay';
import cgu from '../pdf/CGU.pdf'
import confidentialite from '../pdf/politique.pdf'
import loadingSvg from '../styles/img/loader.svg'
import { isAllowedToPay, MINIMUM_PRICE } from '../utils/Validator';
import { notifyWarning } from '../utils/Notify';
import { getOrderAmount } from '../utils/PriceUtils';
import KRGlue from '@lyracom/embedded-form-glue';
import { getUserEmail } from '../utils/StringUtils';
import { successPaidOrder } from '../actions/cartActions';
import { addUsedPromo } from '../actions/userActions';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { log } from 'qrcode/lib/core/galois-field';

const Checkout = ({ edenredErrorMessage, setEdenredErrorMessage, edenredIsLoading, promoLoaded }) => {
  const axiosInstance = useAxiosInterceptors();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { store } = useSelector((state) => state.store);
  const conecs = useSelector((state) => state.user.conecs);
  const user = useSelector((state) => state.user);
  const [conecsBalance, setConecsBalance] = useState();
  const [checkedCB, setCheckedCB] = useState('');
  const [checkcondition, setCheckcondition] = useState(user && user._id && user._id.length > 0);
  const [payBtn, setPayBtn] = useState(true);
  const [paddingBottom, setPaddingBottom] = useState('20rem');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loadingpayment, setLoadingpayment] = useState(false)
  const [date, setDate] = useState();
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
  const [payzen, setPayzen] = useState(undefined);

  // const payzenTokenRef = useRef(undefined);

  const paygreenjsRef = useRef(window.paygreenjs);
  const { cartItems } = cart;
  const cbRef = useRef();

  const isPayzenModule = store?.paymentGateway === 'payzen'; // TODO : rename

  const dispatchData = (data, payzenSavedCard) => {
    dispatch(successPaidOrder(data));
    dispatch(addUsedPromo(user, axiosInstance, cartItems, !!payzenSavedCard, payzenSavedCard));
  };

  const updateOrder = (email, paymentIntent, paymentMethod, payzenSavedCard) => {
    const userEmail = email
    axiosInstance.put(`/orders/${cartItems._id}/pay`, { userEmail, paymentIntent, paymentMethod })
                 .then((response) => {
                   dispatchData(response.data, payzenSavedCard);
                 })
                 .catch((error) => {
                   console.error({ error });
                 })
  };

  useEffect(() => {
    var today = new Date();
    if (today.getDay() == 0) setDate('sunday');
    try {
      if (localStorage.getItem('Conecs')) setConecsBalance((JSON.parse(localStorage.getItem('Conecs')).balance) / 100)

    } catch (error) {
      console.error({ error });
    }

  }, []);

  window.addEventListener('Conecs', () => {
    if (localStorage.getItem('Conecs')) {
      setConecsBalance((JSON.parse(localStorage.getItem('Conecs')).balance) / 100)
    }
  })

  const vibrationButton = () => {
    if (document.querySelector('.conditioncheckbox')) {
      document.querySelector('.conditioncheckbox').classList.add('vibrationred')
      setTimeout(() => {
        document.querySelector('.conditioncheckbox').classList.remove('vibrationred')
      }, 1000);
    }
  }
  const paymentPopup = () => {

    if (!isAllowedToPay(cartItems)) {
      notifyWarning(
        `Panier inférieur à ${MINIMUM_PRICE}€`,
        'Le montant total du panier doit être d\'un montant minimum de 1€ pour passer à l\'étape de paiement.'
      );
      return;
    }
    if (checkcondition) {
      if (!isPopupOpen) {
        setIsPopupOpen(true);
      } else {
        setIsPopupOpen(false);
      }
    } else {
      vibrationButton();
    }
  };

  const handleOpen = (openEvent) => {
    setIsPopupOpen(openEvent);
  };

  useEffect(() => {
    if (cartItems.isPaid === true) {
      handleOpen(false)
    }
  }, [cartItems]);
  const handleBalance = (balanceEvent) => {
    // setEdenredBalance(balanceEvent);
  };

  const handleCheckedCB = (cbEvent) => {
    setCheckedCB(cbEvent);
  };

  useEffect(() => {
    const cartOrder = document.getElementById('cartOrder');
    if (cartOrder) cartOrder.style.paddingBottom = paddingBottom;

  }, [paddingBottom]);

  const handlecondition = () => {
    const condition = document.getElementById('cbx');
    condition.click()
    setCheckcondition(!checkcondition)
  }
  const setPayzenToken = (event) => {
    setPayzen(event)
  }

  return (paygreenjsRef ?
      <>

        <div className=" flex flex-col payer_container justify-end items-center pt-6">

          {user && user._id && user._id.length > 0 ? (
            <></>
          ) : (
            <div className="conditionDutilisation px-6">
              <div className="flex">
                <input type="checkbox" name="Conditions" id="cbx" />
                <label onClick={handlecondition} htmlFor="conditions" className={'conditioncheckbox cbx'}></label>
              </div>
              <div style={{ fontFamily: 'Open Sans', fontWeight: 400 }}>
                <label onClick={handlecondition} htmlFor="conditions" className="conditionlabel">J'accepte les
                  <a href={cgu} target="_blank" className="cgu"> Conditions Générales d'Utilisation </a> et la <a
                    className="cgu" href={confidentialite}
                    target="_blank">politique
                    de confidentialité</a> de PikkoPay
                </label>
              </div>
            </div>
          )}
          <div className="flex items-center payer w-[90%] bottom-btn-shadow ">
            <div
              className={(!checkcondition ? 'filter grayscale  w-full flex items-center justify-center payer p-6 opacity-[0.8]  ' : 'w-full flex items-center justify-center payer p-6 ')}
              onClick={paymentPopup}
            >
              <p>
                {loadingpayment
                  ? <img className="w-10" src={loadingSvg} alt="" />
                  : <>
                    <span className="text-[1.7rem] flex justify-center" style={{ fontFamily: 'geomanistbold' }}>Payer par carte bancaire</span>
                  </>
                }
              </p>
            </div>
          </div>
          <div className="w-[90%] rounded-[1em] overflow-hidden mt-[10px]">
            {isPayzenModule
              ? (
                <>
                  {!user?._id && !checkcondition && (
                    <button
                      className="w-full h-[50px] absolute z-[999]"
                      onClick={vibrationButton}
                    />
                  )}
                  {/*{localStorage.getItem('token') && JSON.parse(localStorage.getItem('token')).paymentToken ? (*/}
                  {payzen && payzen.paymentToken ? (

                    <div id="payzenApplepay">
                      <div className="kr-smart-button" kr-payment-method="APPLE_PAY"></div>
                    </div>

                  ) :
                    (
                      <div>Loading ApplePay...</div>
                    )}
                </>
              )
              : (
                <ApplePay
                  order={cartItems}
                  axiosInstance={axiosInstance}
                  canPay={checkcondition}
                  setPaymentErrorMessage={setPaymentErrorMessage}
                />
              )
            }
          </div>
          {!isPopupOpen ? (
            <>
              {payBtn
                ? (<></>)
                : (
                  <>
                    <div className="flex justify-center">
                      <div className="lds-spinner scale-50">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </>
                )}
            </>
          ) : (
            <></>
          )}
          <PopupPayment
            ref={cbRef}
            open={handleOpen}
            openStatus={isPopupOpen}
            order={cartItems}
            store={store}
            axiosInstance={axiosInstance}
            edBalance={handleBalance}
            cb={handleCheckedCB}
            paymentErrorMessage={paymentErrorMessage}
            setPaymentErrorMessage={setPaymentErrorMessage}
            edenredErrorMessage={edenredErrorMessage}
            setEdenredErrorMessage={setEdenredErrorMessage}
            edenredIsLoading={edenredIsLoading}
            isPayzenModule={isPayzenModule}
            promoLoaded={promoLoaded}
            setPayzenToken={setPayzenToken}
          />
        </div>
      </>
      :
      <>Loading....</>
  )
    ;
};

export default Checkout;
