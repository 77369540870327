import { forwardRef, React, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CartLength from './CartLength';
import { addToCart, getCartInfo, getCartPromo, removeFromCart } from '../actions/cartActions';
import useAxiosInterceptors from '../axios/useAxios';
import paniervide from '../styles/img/paniervide.svg';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { cartPrice, formatFidelityPoints, formatPriceWithEuro } from '../utils/StringUtils';
import moins_icon from '../styles/img/arrow/moins.svg';
import plus_icon from '../styles/img/arrow/plus.svg';
import { isAllowedToPay, MINIMUM_PRICE } from '../utils/Validator';
import cartdelete from '../styles/img/cart_delete.svg';
import LoaderWithText from './loader/LoaderWithText';
import pikkocoin from '../styles/img/Pikkocoin.svg';
import CalculatePromoButton from './button/CalculatePromoButton';
import PromoAndPointsDisplay from './tile/PromoAndPointsDisplay';
import { CART, INVITATION, PROMO } from '../constants/routes';

const FooterCart = ({ scanPause }, ref) => {
  const sheetRef = useRef(null);
  const axiosInstance = useAxiosInterceptors();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loadingCart, cartItems } = useSelector((state) => state.cart);
  const { store } = useSelector((state) => state.store);
  const user = useSelector((state) => state.user);
  const [showCart, setShowCart] = useState(false);
  const [localQty, setLocalQty] = useState([]);
  const [lastCallTimestamp, setLastCallTimestamp] = useState(0);
  const [lastCallTime, setLastCallTime] = useState(0);
  const [cartChange, setCartChange] = useState(false);

  const SNAP = 'SNAP';
  const DONTLOOP = 'Dont_loop';
  const countItems = CartLength();
  const isAllowedToPurchase = isAllowedToPay(cartItems);
  const disablePurchaseButton = () => {
    return countItems === 0 || !isAllowedToPurchase;
  };

  // useEffect(() => {
  //   if (cartItems && showCart) {
  //     dispatch(getCartInfo(cartItems._id, axiosInstance));
  //   }
  // }, [showCart]);
  useEffect(() => {
    let total = 0;
    cartItems.orderItems && cartItems.orderItems.map((item, index) => {
      total += item.price * (localQty[index] || item.Qty);
    });
  }, [localQty, cartItems]);

  const truncate = (str, n) => str.length > n ? str.slice(0, n - 3) + '...' : str;

  const addToCartHandler = (event, product) => {
    event.stopPropagation();
    dispatch(addToCart(cartItems._id, product, 1, axiosInstance));
    setCartChange(true)
  };

  async function removeFromCartHandler(event, product_qty) {
    event.stopPropagation();

    const cbarre_qty = product_qty.map(({ product, qty }) => ({
      Code_Barre: product.Code_Barre,
      qty: qty,
    }));

    dispatch(removeFromCart(cbarre_qty, cartItems._id, product_qty, axiosInstance));
    setCartChange(true)
    // .then(() => {
    //   dispatch(getCartInfo(order._id, axiosInstance));
    //   axiosInstance.put("/track/cartscreen", { id: order._id });
    // });
  };
  const onClickInfooter = () => {
    scanPause(!showCart)
    setShowCart(!showCart)
    if (showCart) {
      sheetRef.current.snapTo(100, { source: DONTLOOP });
    } else {
      sheetRef.current.snapTo(1000, { source: DONTLOOP });
    }
  }

  const handleShowCart = () => {
    const now = Date.now();
    // Vérifie si moins de 500 millisecondes se sont écoulées depuis le dernier appel,c'est pour éviter les appels multiples
    if (now - lastCallTime < 500) {
      setLastCallTime(now); // Met à jour le temps du dernier appel de la fonction
      return;
    }
    if (showCart) {
      sheetRef.current.snapTo(100);
    } else {
      sheetRef.current.snapTo(1000);
    }
    setTimeout(() => {
      setShowCart(!showCart);
    }, 150);

    if (countItems < store.item_limit) {
      scanPause(!showCart);
    }
    // Met à jour le temps du dernier appel
    setLastCallTime(now);
  };

  useImperativeHandle(ref, () => ({
    onClickInfooter,
    handleShowCart,
  }));
  const onclickExterior = (e) => {
    setShowCart(false);
    scanPause(false)
    sheetRef.current.snapTo(100, { source: DONTLOOP });
  };

  const toPay = (e) => {
    e.stopPropagation();
    const currentTimestamp = Date.now();
    if (!showCart) {
      setShowCart(true);
      sheetRef.current.snapTo(1000, { source: DONTLOOP });
      return;
    }
    if(store?.id === '65e6388eb6667e3400b5b8d8') navigate(CART)
    else if ((user && user._id && user._id.length > 0)) navigate(PROMO)
    else navigate(INVITATION)
  };
  const handleCartChange = () => {
    setCartChange(false);
  }

  return (
    <>

      <BottomSheet
        open
        ref={sheetRef}
        className={`chart-slider rounded-t-[25px] relative z-[1009] `}
        onDismiss={onclickExterior}
        scrollLocking={false}
        blocking={showCart}
        defaultSnap={({ maxHeight }) => 120}
        snapPoints={({ maxHeight }) => [maxHeight * 0.7, 120]}
        skipInitialTransition
        expandOnContentDrag={!showCart}
        onSpringStart={(event) => {
          const currentTimestamp = Date.now();
          if (event.type === SNAP && event.source !== DONTLOOP) {
            handleShowCart();
            console.log(currentTimestamp - lastCallTimestamp);
            setLastCallTimestamp(currentTimestamp);
          }
        }}
        headerSize={0}
      >
      {showCart && <div className='header_footer_cart'>
        <span className='header_footer_cart_text'>
          Panier
        </span>
        <CalculatePromoButton
          store={store}
          cartItems={cartItems}
          axiosInstance={axiosInstance}
          showCart={showCart}
          isCartChanged={cartChange}
          handleCartChange={handleCartChange}
        />
      </div> }
        {loadingCart && showCart ? (

          // A mettre au milieu du panier
          <LoaderWithText text="Chargement..." isRounded />
        ) : (
          <footer
          id="footerPanier"
          className={`footer-container bottom-0 rounded-t-[25px]  duration-300  bg-white flex flex-col items-center
                    ${showCart ? 'open overflow-auto  ' : 'h-[8vh] overflow-hidden'}`}
          onClick={onClickInfooter}
        >
          {showCart ? (
            <>

              {loadingCart ? (
                <>
                  <div
                    className="absolute z-50 h-full w-full  "
                    style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                  >
                    <div className="z-50 absolute h-full left-2/4 top-[25%]  -translate-x-2/4 ">
                      <div className="lds-spinner white">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </>
              ) : <></>}
              <>
                {/*<ul className="w-full relative h-full px-6 mb-[150px] flex flex-col">*/}
                <ul className="item-container">
                  {!countItems ? (
                    <div className="absolute transform translate-x-[-50%] translate-y-[-50%] top-[40%] left-[50%]">
                      <img src={paniervide} alt="" />
                    </div>
                  ) : (
                    <></>
                  )}


                  {cartItems.orderItems && cartItems.orderItems.map((item, index) => {
                    return (

                      <li key={index} className="mt-0">
                        <div
                          className="cart_list p-4 flex justify-between items-center bg-white  "
                          style={{ borderColor: item.CountInStock }}
                        >
                          
                          <div className="flex  items-center justify-center gap-8 overflow-hidden">
                            <img
                              src={item.image}
                              alt={item.image}
                              className="object-contain"
                              style={{ height: '55px', width: '55px' }}
                            ></img>
                            <div className="py-2 ml-4">
                              <div className="min-30 text-left text-xl flex flex-col gap-4"
                                   style={{ fontFamily: 'Open Sans' }}>
                              <PromoAndPointsDisplay item={item} pikkocoin={pikkocoin} user={user} />
                                <p className="mb-2">
                                  {truncate(item.name, 26)}
                                </p>
                                <p className="cart-item-price">
                                  <div>
                                    {
                                      item?.promoPrice >= 0? (
                                        <>
                                        {formatPriceWithEuro(item.promoPrice*item.Qty)}
                                        </>
                                      ) : (
                                        <>
                                        {formatPriceWithEuro(item.price*item.Qty)}
                                        </>
                                        )
                                    }

                                    {
                                      item.promoPrice && item.price && (
                                        <span className="promotion_sliced">
                                          {formatPriceWithEuro(item.price*item.Qty)}
                                        </span>
                                      )
                                    }
                                  </div>
                                
                                </p>
                              </div>
                            </div>
                          </div>
                          {item.price === 0 ? (
                            <></>
                          ) : (
                            <div className="min-30_price rounded-full border-solid py-1 grid grid-cols-3 h-[40px] w-[100px]
                                            items-center text-2xl relative justify-between px-4 flex
                                            ">
                              <button
                                className=" items-center justify-center h-full flex items-center"
                                onClick={(event) =>
                                  removeFromCartHandler(event, [
                                    { product: item, qty: 1 },
                                  ])
                                }
                              >
                                {item.Qty === 1 ?
                                  <>
                                    <img src={cartdelete} alt="delete" className="w-auto" />
                                  </>
                                  :

                                  <img src={moins_icon} alt="" />
                                }
                              </button>
                              {localQty[index]
                                ? <span
                                  className=" items-center text-center text-2xl  h-full flex  justify-center items-center">{localQty[index]}</span>
                                : <span
                                  className=" items-center text-center text-[1.7rem] geomanistBold  h-full  justify-center flex items-center">{item.Qty}</span>}
                              {countItems >= store.item_limit ? (

                                <></>
                              ) : (
                                <button
                                  className=""
                                  onClick={(event) => addToCartHandler(event, item)}
                                >
                                  <img src={plus_icon} alt="" />
                                </button>
                              )}
                            </div>
                          )}

                        </div>
                      </li>
                    );
                  })}
                </ul>
              </>
            </>
          ) : (<></>)}
            {showCart && disablePurchaseButton() && (
              <div className="warning-message">{`Panier minimum de ${formatPriceWithEuro(MINIMUM_PRICE)}`}</div>
          )}
           {showCart && (
                <div className="price_footer w-full gap-4  text-[1.7rem] geomanistMedium">
                  <div className="flex w-full justify-around items-center gap-6  ">
                    <div className="flex flex-col w-full items-start open-sans-bold">
                      <span className="color-label text-[16px] open-sans-bold ">Total à payer </span>

                      <span className="color-label text-xl open-sans" style={{
                        fontFamily: 'Open Sans',
                        color: '#9C9C9C'
                      }}>{countItems > 1 ? `${countItems} articles` : `${countItems} article`}</span>
                    </div>
                    <div className="flex flex-col">
                      <p className="color-label text-[16px] open-sans-bold">
                        {formatPriceWithEuro(cartPrice(cartItems))}
                        {
                          cartItems.promoPrice !== cartItems.itemsPrice && (
                            <span className="promotion_sliced">
                              {formatPriceWithEuro(cartItems.itemsPrice)}
                            </span>
                          )
                        }
                      </p>
                    </div>
                  </div>
                </div>
              )
              }
          <div className={`purchase-button-container
                            ${showCart ? (!disablePurchaseButton() ? 'payer p-6 pl-100 !rounded-[1.5em] bottom-btn-shadow' : 'filter grayscale !rounded-[1.5em] ') : <></>}`}>
            {!showCart ? <div className="w-full flex justify-center items-center">
              <div id="footerCart" className="flex flex-col w-full items-start  justify-center geomanistMedium inter">
                        <span className="text-[22px] open-sans-bold"
                        >Panier</span>
                <div className="text-xl  !text-[1.5rem]"
                     style={{
                       fontFamily: 'Open Sans ',
                       color: '#9C9C9C'
                     }}>{countItems > 1 ? `${countItems} articles` : `${countItems} article`}</div>
              </div>
              <div className=" text-[22px] open-sans-bold">
                {formatPriceWithEuro(cartPrice(cartItems))}
              </div>
            </div> : <></>}

            <button
              onClick={toPay}
              disabled={disablePurchaseButton() && showCart}
              className={`purchase-button ${disablePurchaseButton() && showCart ? 'disabled' : (!showCart ? 'footer-cart-button bottom-btn-shadow' : '')}`}
            >
           

              <div
                className={showCart ? ' color-label  w-[130px] text-[1.9rem] geomanistMedium' : ' color-label  text-[1.7rem] w-[130px]  geomanistMedium'}>
                {
                  showCart ? <>Valider/Payer</> : <>Voir mon panier</>
                }

              </div>
            </button>
          </div>
        </footer>
        )}

      </BottomSheet>
    </>
  );
};

export default forwardRef(FooterCart);
